import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import API from 'api'
import { convertToQueryString } from 'helpers'
import Redirect from 'helpers/redirect'
import { useTranslation } from 'i18n-web/i18next'
import { resetMonthlyParking } from 'redux-web/utils/monthly/actions'

import Index from './Screen'

const IndexHOC = Component => {
  const Wrapper = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(resetMonthlyParking())
    }, [])

    return <Component {...props} t={t} />
  }

  Wrapper.getInitialProps = async ctx => {
    const pageProps = {}
    const {
      key,
      initialRoute,
      parentDomain,
      sdk,
      disableHeaderAndFooter,
      phoneNumberSupport,
      emailSupport,
      token,
    } = ctx?.query

    if (key) {
      const application = await API()
        .getApplicationInfo(key, { ...(sdk && { sdk: true }) })
        .then(resp => (!resp.error ? resp.application : resp))
        .catch(err => console.error(err))

      if (!application?.error) {
        let url

        if (initialRoute && initialRoute !== 'events') {
          url = `/${application.themeName}/${initialRoute}`
        } else {
          url = `/${application.themeName}/events`
        }

        if (parentDomain) {
          url = `${url}?sdk=${sdk}&parentDomain=${parentDomain}`

          if (disableHeaderAndFooter) {
            url = `${url}&disableHeaderAndFooter=${disableHeaderAndFooter}`
          }

          if (phoneNumberSupport) {
            url = `${url}&phoneNumberSupport=${phoneNumberSupport}`
          }

          if (emailSupport) {
            url = `${url}&emailSupport=${emailSupport}`
          }

          // Redirect to [Application] home screen
          if (sdk && token) {
            Redirect(
              `/${application.themeName}/?${convertToQueryString(ctx.query)}`,
              ctx
            )
          }
        }

        Redirect(url, ctx)
      }
    }

    return { pageProps }
  }

  return Wrapper
}

export default IndexHOC(Index)
